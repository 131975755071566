import React from "react";
import { Slider } from "app/components/Slider";
import ProgramCard from "app/components/ProgramCard";
import Flex from "app/components/Flex";
import { H2 } from "app/components/Typography";
import { ProgramV2 } from "services/graphql";
import { Link } from "react-router-dom";

interface Props {
  programs: Partial<ProgramV2>[];
}

export function ProgramsCarousel({ programs }: Props) {
  if (!programs) {
    return null;
  }
  return (
    <Flex bg="black" justifyContent="center" mb={4}>
      <Flex
        width="100%"
        color="white"
        maxWidth="1600px"
        flexDirection="column"
        p={[3, 3, 4]}
      >
        <H2 color="white" mb="16px">
          Programs
        </H2>
        {programs.length === 0 ? (
          <span>
            You haven&apos;t started or completed a program. Checkout our{" "}
            <Link to="/programs">programs</Link> to get started dancing.
          </span>
        ) : (
          <Slider
            data={Array.from(programs)}
            slideComponent={ProgramCard}
            selectedFrom="DancePrograms"
            arrowTopPosition="35%"
            slidesSpacing={32}
            slidesWidth={280}
          />
        )}
      </Flex>
    </Flex>
  );
}
